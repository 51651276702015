// Generated by Framer (b12dec4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["xPXwuH_lI"];

const variantClassNames = {xPXwuH_lI: "framer-v-11ab21g"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, tap, width, ...props}) => { return {...props, f3AC8EfVl: image ?? props.f3AC8EfVl ?? {src: new URL("assets/vtPEoXrja4q2D3Llf0CZ40VJjqQ.png", import.meta.url).href}, YHXfehbwI: tap ?? props.YHXfehbwI} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string};tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, f3AC8EfVl, YHXfehbwI, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "xPXwuH_lI", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap3aqkww = activeVariantCallback(async (...args) => {
if (YHXfehbwI) {
const res = await YHXfehbwI(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-JXwLZ", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 469, intrinsicWidth: 268, pixelHeight: 469, pixelWidth: 268, sizes: "min(400px, 100vw)", ...toResponsiveImage(f3AC8EfVl)}} className={cx("framer-11ab21g", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"xPXwuH_lI"} onTap={onTap3aqkww} ref={ref} style={{...style}}/></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-JXwLZ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-JXwLZ .framer-1846qxc { display: block; }", ".framer-JXwLZ .framer-11ab21g { cursor: pointer; height: 468px; position: relative; width: 400px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 468
 * @framerIntrinsicWidth 400
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"f3AC8EfVl":"image","YHXfehbwI":"tap"}
 * @framerImmutableVariables false
 */
const FramerrQ99KA5x1: React.ComponentType<Props> = withCSS(Component, css, "framer-JXwLZ") as typeof Component;
export default FramerrQ99KA5x1;

FramerrQ99KA5x1.displayName = "c";

FramerrQ99KA5x1.defaultProps = {height: 468, width: 400};

addPropertyControls(FramerrQ99KA5x1, {f3AC8EfVl: {__defaultAssetReference: "data:framer/asset-reference,vtPEoXrja4q2D3Llf0CZ40VJjqQ.png?originalFilename=ff.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}, YHXfehbwI: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerrQ99KA5x1, [])